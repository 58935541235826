@import '../../styles/partials/mixins';
@import '../../styles/partials/variables';


.social-media-link {
	&__img {
		height: 24px;

		@include desktop {
			height: 18px;
		}
	}
}