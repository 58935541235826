@import '../../styles/partials/mixins';

.social-nav {
	&--link-img {
		height: 16px;

		@include tablet {
			height: 28px;
		}
	}
}