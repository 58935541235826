@import './variables';
@import './fonts';

// Media Queries
@mixin custom($min-width) {
	@media (min-width: $min-width) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: $tablet-breakpoint) {
		@content;
	}
}

@mixin desktop-header {
	@media (min-width: $header-desktop-breakpoint) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: $desktop-breakpoint) {
		@content;
	}
}

@mixin desktop-large {
	@media (min-width: $desktop-large-breakpoint) {
		@content;
	}
}


// FONTS
@mixin montserrat-font($weight: 400, $style: normal) {
	font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif;
	font-weight: $weight;
	font-style: $style;
}


@mixin body-tablet__desk {
	font-size: 14px;
	line-height: 22px;
	@include montserrat-font;
}

@mixin h1-tablet__desk {
	font-size: 33px;
	@include montserrat-font(800, italic);
}

@mixin h2-tablet__desk {
	font-size: 24px;
	@include montserrat-font(700, normal);
}

@mixin h3-tablet__desk {
	font-size: 16px;
	@include montserrat-font(700, normal);
}

@mixin p-tablet__desk {
	font-size: 14px;
	@include montserrat-font;
}


// Text Styling -- MOBILE
@mixin body-mobile {
	font-size: 13px;
	@include montserrat-font;
}

@mixin h1-mobile {
	font-size: 24px;
	@include montserrat-font(800, italic);
}

@mixin h2-mobile {
	font-size: 16px;
	@include montserrat-font(700, normal);
}

@mixin h3-mobile {
	font-size: 16px;
	@include montserrat-font(700, normal);
}

@mixin p-mobile {
	font-size: 13px;
	@include montserrat-font;
}



// BUTTONS
@mixin button {
	border: 1px solid #000;
	border-radius: 6px;
	cursor: pointer;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 40px;
	transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
	@include montserrat-font(300, normal);


	&:hover {
		border: 1px solid lightslategray;
		color: lightslategray;
	}

	@include tablet {
		width: 200px;
		font-size: 18px;
	}

	@include desktop {
		height: 45px;
	}
}