@import '../../styles/partials/variables';
@import '../../styles/partials/mixins';

.hero {
	min-height: calc(100vh - 114.71px);
	background-color: #000;
	width: 100%;

	@include tablet {}

	@include desktop-header {}

	@include desktop {}

	&__video {
		min-height: calc(100vh - 89.054px);
		width: 100%;
		object-fit: cover;

		@include tablet {
			min-height: calc(100vh - 99.313px);
			max-height: calc(100vh - 99.313px);
		}

		@include desktop-header {
			min-height: calc(100vh - 114.71px);
			max-height: calc(100vh - 114.71px);
		}

		@include desktop {}
	}
}

@media (prefers-reduced-motion: reduce) {
	.hero__video {
		display: none;
	}
}